import { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { routes } from "./routes";
import { Sidebar } from "./components";
import { Login, NotFoundPage } from "./pages";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  const location = useLocation();
  const isLoginPage = location.pathname === "/";
  const shouldRenderSidebar = !isLoginPage;

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box className="app" sx={{}}>
          {shouldRenderSidebar && <Sidebar isSidebar={isSidebar} />}
          <main className="content">
            <Routes>
              {routes.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={route.element}
                />
              ))}
              {/* If the path is "/", render the Login component */}
              <Route path="/" element={<Login />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </main>
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
