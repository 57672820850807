import { Box, MenuItem, Select, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import {
  headerNamesEca,
  headerNamesFSCA,
} from "../../constants/Charts/pieData";
import {
  CustomCard,
  HighRiskEquipmentTable,
  HorizontalBars,
  Maps,
  Topbar,
  VerticalBarChart,
} from "../../components";
import CustomPieChart from "../../components/Charts/PieChart/CustomPieChart";
import {
  getSummaryRiskFsca,
  getSummaryRiskEca,
  getSummaryOSBL,
  getSummaryISBL,
  getMapLocation,
  getSummaryTopHighlight,
  getSummaryByUnit,
} from "./Service/axios";
import { useEffect, useState } from "react";
import DynamicSnackbarAlert from "../../components/Global/DynamicSnackbarAlert";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // State variables to store data from API calls
  const [riskFscaSummary, setRiskFscaSummary] = useState([]);
  const [riskEcaSummary, setRiskEcaSummary] = useState([]);
  const [mapLocationData, setMapLocationData] = useState([]);
  const [riskColors, setRiskColors] = useState([]);
  const [riskColorsEca, setRiskColorsEca] = useState([]);
  const [topHighlightData, setTopHighlightData] = useState(null);
  const [osblSummary, setOsblSummary] = useState([]);
  const [isblSummary, setIsblSummary] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState("U_331");
  const [selectedUnit, setSelectedUnit] = useState("U_331");
  const [unitSummary, setUnitSummary] = useState([]);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [blurScreen, setBlurScreen] = useState(false);

  const handleSnackbar = (severity, message) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);

    // Automatically close the snackbar after 2000 milliseconds (2 seconds)
    setTimeout(() => {
      setSnackbarOpen(false);
    }, 1500);
  };

  const fscaRiskOrder = ["E", "H", "MH", "M", "L", "N"];
  const ecaRiskOrder = ["E", "H", "MH", "M", "L", "N"];

  const handleChange = (event) => {
    setSelectedLocationId(event.target.value);
    setSelectedUnit(event.target.value); // Update the selected unit
  };

  // Fetch unit summary data
  const fetchUnitSummary = async (unitId) => {
    try {
      const unitSummaryData = await getSummaryByUnit(unitId);
      setUnitSummary(unitSummaryData);
    } catch (error) {
      console.error("Error fetching unit summary data:", error);
      setError(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setBlurScreen(true);
        // Fetch map location data
        const mapLocationData = await getMapLocation();
        setMapLocationData(mapLocationData);
    
        // Fetch and process riskFscaData
        const riskFscaData = await getSummaryRiskFsca();
        if (riskFscaData && riskFscaData.length > 0) {
          // Extract all unique risk levels
          const allRiskLevels = [...new Set(riskFscaData.map(data => data.risk))];
    
          // Initialize an empty array to hold the formatted data
          const formattedRiskFscaData = [];
    
          // Iterate over all risk levels and check if data is available for each
          allRiskLevels.forEach(riskLevel => {
            const riskData = riskFscaData.find(data => data.risk === riskLevel);
            const jumlah = riskData ? riskData.jumlah : 0;
            formattedRiskFscaData.push([riskLevel, jumlah]);
          });
    
          // Sort the formatted data based on predefined order
          const sortedRiskFscaData = formattedRiskFscaData.sort(
            (a, b) => fscaRiskOrder.indexOf(a[0]) - fscaRiskOrder.indexOf(b[0])
          );
    
          // Extract colors for each risk level
          const colors = sortedRiskFscaData.map((item) => {
            const riskData = riskFscaData.find(data => data.risk === item[0]);
            return riskData ? riskData.riskColor : '#000000';
          });
    
          setRiskColors(colors);
          setRiskFscaSummary([["Risk", "Jumlah"], ...sortedRiskFscaData]);
          handleSnackbar("success", "Risk FSCA data successfully loaded");
          await new Promise((resolve) => setTimeout(resolve, 1000));
        } else {
          console.error("Error: No data or invalid data received for riskFsca");
          handleSnackbar("error", "Error loading Risk FSCA data");
        }

        // Fetch and process riskEcaData
        const riskEcaData = await getSummaryRiskEca();
        if (riskEcaData && riskEcaData.length > 0) {
          const sortedRiskEcaData = riskEcaData.sort(
            (a, b) =>
              ecaRiskOrder.indexOf(a.risk) - ecaRiskOrder.indexOf(b.risk)
          );
          const formattedRiskEcaData = [
            ["Risk", "Jumlah"],
            ...sortedRiskEcaData.map(({ risk, jumlah }) => [risk, jumlah]),
          ];
          const colorsEca = sortedRiskEcaData.map((item) => item.riskColor);
          setRiskColorsEca(colorsEca);
          setRiskEcaSummary(formattedRiskEcaData);
          handleSnackbar("success", "Risk ECA data successfully loaded");
          await new Promise((resolve) => setTimeout(resolve, 2000));
        } else {
          console.error("Error: No data or invalid data received for riskEca");
          handleSnackbar("error", "Error loading Risk ECA data");
        }

        // Fetch and set OSBL data
        const osblData = await getSummaryOSBL();
        setOsblSummary(osblData);

        // Fetch and set ISBL data
        const isblData = await getSummaryISBL();
        setIsblSummary(isblData);

        // Fetch and set top highlight data
        const topHighlightData = await getSummaryTopHighlight();
        setTopHighlightData(topHighlightData);

        // Show success snackbar
        handleSnackbar("success", "Data successfully loaded");
        if (selectedUnit) {
          await fetchUnitSummary(selectedUnit);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
        handleSnackbar("error", "Error fetching data");
      }
      setBlurScreen(false);
    };

    fetchData();
  }, [selectedUnit]);

  return (
    <>
      <DynamicSnackbarAlert
        open={snackbarOpen}
        severity={snackbarSeverity}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />

      <Box
        sx={{
          backgroundColor: colors.primary[300],
          maxHeight: "100vh",
          minHeight: "100vh",
          padding: "10px",
          filter: blurScreen ? "blur(8px)" : "none", // Apply blur effect
        }}
      >
        {/* HEADER */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          height="25px"
          borderBottom="1px solid blue"
          paddingBottom="10px"
        >
          <Typography
            sx={{
              fontFamily: "Montserrat, sans-serif",
              color: colors.primary[100],
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "normal",
              textTransform: "uppercase",
            }}
          >
            Main Dashboard Monitoring
          </Typography>
          <Topbar />
        </Box>

        {/* GRID & CHARTS */}
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="calc((100vh - 14vh) / 3) !important"
          gap="1em"
          mt="10px"
        >
          {/* MAPS */}
          <Box
            gridColumn="span 9"
            gridRow="span 2"
            backgroundColor="transparent"
            style={{
              boxShadow: "rgba(0, 0, 0, 0.3) 2px 2px 8px",
            }}
            borderRadius="5px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            width="100%"
            height="100%"
          >
            <Maps mapLocationData={mapLocationData} />{" "}
          </Box>
          <CustomCard
            gridColumn="span 3"
            gridRow="span 1"
            title="FSCA RISK"
            backgroundColor={colors.primary[400]}
            boxShadow="rgba(0, 0, 0, 0.3) 2px 2px 8px"
            borderRadius="5px"
          >
            <CustomPieChart
              data={riskFscaSummary}
              colors={colors}
              headerNames={headerNamesFSCA}
              riskColors={riskColors}
              detailEndpoint="detailRiskFsca"
            />
          </CustomCard>
          <CustomCard
            gridColumn="span 3"
            gridRow="span 1"
            backgroundColor={colors.primary[400]}
            boxShadow="rgba(0, 0, 0, 0.3) 2px 2px 8px"
            borderRadius="5px"
            title="ECA RISK"
          >
            <CustomPieChart
              data={riskEcaSummary}
              colors={colors}
              headerNames={headerNamesEca}
              riskColors={riskColorsEca}
              detailEndpoint="detailRiskEca"
            />
          </CustomCard>
          <CustomCard
            gridColumn="span 3"
            gridRow="span 1"
            backgroundColor={colors.primary[400]}
            boxShadow="rgba(0, 0, 0, 0.3) 2px 2px 8px"
            borderRadius="5px"
            title="UNIT SUMMARY"
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Select
                sx={{
                  width: "50%",
                  "&::-webkit-scrollbar": {
                    width: "8px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: colors.blueAccent[900],
                    borderRadius: "0px",
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: colors.grey[800],
                  },
                }}
                size="small"
                value={selectedLocationId}
                onChange={handleChange}
                MenuProps={{
                  style: { maxHeight: 200 },
                }}
              >
                {mapLocationData.map((location) => (
                  <MenuItem key={location.id} value={location.id}>
                    {location.id}
                  </MenuItem>
                ))}
              </Select>
              <Box
                sx={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ textAlign: "center" }}>Title EQ</Typography>
              </Box>
            </Box>
            {/* Render the HorizontalBars component */}
            <HorizontalBars
              unitId={selectedLocationId}
              unitSummary={unitSummary}
            />
          </CustomCard>{" "}
          <CustomCard
            gridColumn="span 3"
            gridRow="span 1"
            backgroundColor={colors.primary[400]}
            boxShadow="rgba(0, 0, 0, 0.3) 2px 2px 8px"
            borderRadius="5px"
            title="OSBL UNIT"
          >
            <VerticalBarChart realData={osblSummary} />
          </CustomCard>
          <CustomCard
            gridColumn="span 3"
            gridRow="span 1"
            backgroundColor={colors.primary[400]}
            boxShadow="rgba(0, 0, 0, 0.3) 2px 2px 8px"
            borderRadius="5px"
            title="ISBL UNIT"
          >
            <VerticalBarChart realData={isblSummary} />
          </CustomCard>
          <CustomCard
            gridColumn="span 3"
            gridRow="span 1"
            backgroundColor={colors.primary[400]}
            boxShadow="rgba(0, 0, 0, 0.3) 2px 2px 8px"
            borderRadius="5px"
            title="TOP HIGHLIGHT EQUIPMENT RISK"
          >
            <HighRiskEquipmentTable
              equipmentData={topHighlightData}
              colors={colors}
            />
          </CustomCard>{" "}
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
