import axios from 'axios';

const pfdInstance = axios.create({
  baseURL: 'https://be-fsca.aidainspection.com/api/Pfd', // Corrected base URL for PFD
  headers: {
    'Content-Type': 'application/json',
    // Add any common headers here
  },
});

// Add request interceptor
const requestInterceptor = (config) => {
  // You can modify the request config here, such as adding authentication headers
  return config;
};

pfdInstance.interceptors.request.use(requestInterceptor);

// Add response interceptor
const responseInterceptor = (response) => {
  // You can modify the response here
  return response;
};

pfdInstance.interceptors.response.use(responseInterceptor);

export const fetchData = async (detailEndpoint, selectedRisk) => {
    try {
      const response = await fetch(
        `https://be-fsca.aidainspection.com/api/Dashboard/${detailEndpoint}?risk=${selectedRisk}`
      );
  
      if (response.ok) {
        return await response.json();
      } else {
        console.error(`Failed to fetch data for risk: ${selectedRisk}`);
        throw new Error("Failed to fetch data. Please try again.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw new Error("An error occurred. Please try again later.");
    }
  };

  export const getModalAnalythic = async (detailEndpoint, locationId, risk) => {
    try {
      const response = await pfdInstance.get(`/${detailEndpoint}`, {
        params: { locationId, risk },
      });
  
      if (!response.data) {
        throw new Error('No data received from the server');
      }
  
      return response.data;
    } catch (error) {
      throw new Error(`API error: ${error.message}`);
    }
  };
  
  