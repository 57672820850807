export const resumeUnitChartData = [
  ["City", "2010 Population", "2000 Population"],
  ["Electrical", 8175000, 8008000],
  ["Instrument", 3792000, 3694000],
  ["Rotating", 2695000, 2896000],
  ["Static", 2099000, 1953000],
];
export const barChartOptions = {
  title: "Population of Largest U.S. Cities",
  chartArea: { width: "50%" },
  isStacked: true,
  hAxis: {
    title: "Year",
    minValue: 0,
    gridlines: { color: "transparent" },
    textStyle: { color: "#9ca2b7", fontSize: 11, lineHeight: 2 },
  },
  vAxis: {
    title: "Amount",
    gridlines: { color: "transparent" },
    textStyle: { color: "#9ca2b7", fontSize: 11, lineHeight: 2 },
  },
  backgroundColor: {
    fill: "transparent",
  },
  colors: ["#04CA01", "#96FF68", "#FDFF99", "#FF9800", "#BC0C0C", "#FE0000"],
  series: {
    0: { bar: { groupWidth: "100%" } },
  },
};
export const uData = [20000, 30000, 20000, 20780, 18090, 23090, 3490];
export const pData = [24000, 13980, 98000, 39008, 48000, 38000, 4300];
export const amtData = [35000, 22100, 22900, 20000, 21081, 25000, 2100];

export const xLabels = ["Electrical", "Instrument", "Rotating", "Static"];
export const colors = [
  "#BC0C0C",
  "#FE0000",
  "#FF9800",
  "#FDFF99",
  "#96FF68",
  "#04CA01",
];
