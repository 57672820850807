export const riskFscaData = [
  ["Task", "Hours per Day"],
  ["N", 10],
  ["L", 3],
  ["M", 1],
  ["MH", 4],
  ["H", 5],
  ["E", 6],
];

export const tableData = [
  {
    equipTag: "ABC123",
    equipType: "Type A",
    equipDescription: "Sample Equipment 1",
    impactOnFS: "Low",
    likeLihood: "Medium",
    critCode: "CR1",
    ramCriticality: "High",
  },
  {
    equipTag: "XYZ456",
    equipType: "Type B",
    equipDescription: "Sample Equipment 2",
    impactOnFS: "Medium",
    likeLihood: "High",
    critCode: "CR2",
    ramCriticality: "Low",
  },
  // Add more items as needed
];

export const headerNamesFSCA = [
  "Equipment Tag",
  "Equipment Type",
  "Equipment Description",
  "Failure Scenario",
  "Impact on the FS",
  "Likelihood",
  "Criticallity Code",
  "Ram Criticality",
];

export const headerNamesEca = [
  "Location",
  "Equipment Tag",
  "Equipment Type",
  "Description",
  "Impact",
  "Likelihood",
  "Criticallity Code",
  "Ram Criticallity",
];

export const pieChartOptions = {
  titleTextStyle: {
    color: "#333",
    fontSize: 16, // Adjusted title font size
    bold: true,
  },
  legend: {
    position: "bottom",
    alignment: "center",
    textStyle: {
      fontSize: 12, // Adjusted legend font size
      color: "#555",
    },
  },
  colors: ["#4CAF50", "#8BC34A", "#FFC107", "#FF9800", "#F44336", "#D32F2F"],
  backgroundColor: {
    fill: "transparent", // Removed background color for a glassmorphism effect
  },
  chartArea: {
    width: "100%",
    height: "80%", // Reduced chart area height
    top: "5%", // Adjusted top margin
  },
  pieHole: 0,
  pieSliceBorderColor: "#FFFFFF",
  pieSliceTextStyle: {
    color: "#333",
    fontSize: 10, // Adjusted slice text font size
  },
  tooltip: {
    trigger: "both",
    isHtml: true,
  },
};
