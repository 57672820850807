import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  useTheme,
  Box,
} from "@mui/material";

const HighRiskEquipment = ({ equipmentData, colors }) => {
  return (
    <Grid container spacing={2}>
      {equipmentData && equipmentData.map((equipment, index) => (
        <Grid item xs={12} key={index}>
          <EquipmentCard equipment={equipment} colors={colors} />
        </Grid>
      ))}
    </Grid>
  );
};

const EquipmentCard = ({ equipment, colors }) => {

  return (
    <Card sx={{ boxShadow: "none", backgroundColor: colors.primary[300] }}>
      <CardContent
        sx={{
          padding: "0.5em",
          borderBottom: `2px solid ${colors.blueAccent[700]} !important`,
        }}
      >
        <Typography
          variant="body1"
          sx={{ fontWeight: "bold" }}
          color={colors.blueAccent[600]}
        >
          {`${equipment.tagNumber} | ${equipment.description}`}
        </Typography>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center",  }}
        >
          <Typography variant="body2" sx={{ color: colors.grey[800] }}>
          Ram criticality : 
          </Typography>
          <Typography variant="body2" sx={{ color: "red", marginLeft: "4px" }}>
            {equipment.ram}
          </Typography>
        </Box>
        <Typography variant="body2" sx={{ color: colors.grey[800] }}>
        Impact to FS : {equipment.impact}
        </Typography>
        <Typography variant="body2" sx={{ color: colors.grey[800] }}>
        likelihood :{equipment.likelihood}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default HighRiskEquipment;
