import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { Divider } from "@mui/material";
import { colors } from "../../../constants/Charts/BarChartData";

export default function VerticalBars({ unitId, unitSummary }) {
  const formattedDataset = React.useMemo(() => {
    const result = unitSummary.reduce((acc, { kategori, risk, jumlah }) => {
      const existingCategory = acc.find((entry) => entry.kategori === kategori);

      if (existingCategory) {
        existingCategory[risk] = jumlah;
      } else {
        acc.push({ kategori, [risk]: jumlah });
      }

      return acc;
    }, []);

    return result.map((entry) => {
      // Convert the entry to the desired format
      return {
        kategori: entry.kategori,
        values: ["N", "L", "M", "MH", "H", "E"].map((risk) => ({
          risk,
          jumlah: entry[risk] || 0, // Set to 0 if the risk count is undefined
        })),
      };
    });
  }, [unitSummary]);

  return (
    <>
      <Divider sx={{ mt: 1 }} />
      {unitId && unitSummary.length > 0 && (
        <BarChart
          radius={[10, 10, 10, 10]}
          margin={{ top: 5, bottom: 60, left: 80, right: 20 }}
          dataset={formattedDataset}
          xAxis={[
            {
              scaleType: "band",
              dataKey: "kategori",
              categoryGapRatio: 0.5,
              barGapRatio: 0,
            },
          ]}
          series={[
            ...["E", "H", "MH", "M", "L", "N"].map((risk, index) => ({
              data: formattedDataset.map(
                (item) => item.values.find((v) => v.risk === risk)?.jumlah || 0
              ),
              label: risk,
              stack: "stack1",
              color: colors[index],
            })),
          ]}
          layout="vertical" // Change to vertical layout
          slotProps={{
            legend: {
              labelStyle: {
                fontSize: 10,
              },
              direction: "row",
              position: { vertical: "bottom", horizontal: "middle" },
              padding: 1,
              itemMarkWidth: 11,
              itemMarkHeight: 8,
              markGap: 1,
              itemGap: 20,
            },
          }}
        />
      )}
    </>
  );
}
