import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import {
  Box,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../theme";

const TreeViewComponent = ({ onLocationSelect }) => {
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    fetch("https://be-fsca.aidainspection.com/api/Pfd/treeView")
      .then((response) => response.json())
      .then((data) => setTreeData(data))
      .catch((error) => console.error("Error fetching tree data:", error));
  }, []);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
    const selectedUnitOSBLId = nodeIds.find((nodeId) =>
      nodeId.startsWith("unitOSBL")
    );
    if (selectedUnitOSBLId) {
      const unitIndex =
        parseInt(selectedUnitOSBLId.replace("unitOSBL", ""), 10) - 1;

      // Check if unitIndex is within bounds and kpbData is not empty
      if (unitIndex >= 0 && unitIndex < kpbData.length) {
        const selectedNode = kpbData[unitIndex];
        const locationId = selectedNode.locationId;

        // Filter the data based on the selected locationId
        const selectedLocationData = treeData.find(
          (item) => item.locationId === locationId
        );

        // Check if data is found for the selected locationId
        if (selectedLocationData) {
          // Use the selected data for further processing
          onLocationSelect(selectedLocationData);
        } else {
          console.log(`Data not found for locationId: ${locationId}`);
        }
      } else {
        console.log(`Invalid unitIndex: ${unitIndex}`);
      }
    }
  };

  const handleTreeItemClick = (locationId) => {
    onLocationSelect(locationId);
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Group data by processType
  const groupedTreeData = treeData.reduce((acc, node) => {
    if (!acc[node.processType]) {
      acc[node.processType] = [];
    }
    acc[node.processType].push(node);
    return acc;
  }, {});

  // Extract "KPB" data
  const kpbData = groupedTreeData["KPB"] || [];

  return (
    <Box
      sx={{
        boxShadow:
          "0px 19px 38px rgba(0, 0, 0, 0.3), 0px 15px 12px rgba(0, 0, 0, 0.22)",
        borderRadius: "10px",
        width: "100%",
        padding: "10px",
        maxHeight: "88vh",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        bgcolor: colors.primary[400],
        "&::-webkit-scrollbar": {
          width: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: colors.blueAccent[900],
          borderRadius: "0px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: colors.grey[800],
        },
      }}
    >
      <Typography variant="h5" gutterBottom>
        TreeView
      </Typography>
      <TextField
        label="Search"
        variant="outlined"
        placeholder="Search by System or Unit"
        fullWidth
        size="small"
        sx={{ marginBottom: 2 }}
        onChange={handleSearch}
        value={searchText}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <TreeView
        aria-label="controlled"
        defaultCollapseIcon={<FolderIcon />}
        defaultExpandIcon={<FolderOpenIcon />}
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
        multiSelect
        sx={{
          width: "100%",
          backgroundColor: colors.primary[500],
          borderRadius: "5px",
          "& .MuiTreeItem-label": { fontWeight: "bold" },
          "& .MuiTreeItem-root": {
            borderRadius: "4px",
            marginBottom: "8px",
          },
        }}
      >
        <TreeItem key="KPB" nodeId="1" label="KPB">
          {/* OSBL Group */}
          {groupedTreeData["OSBL"] && (
            <TreeItem key="OSBL" nodeId="2" label="OSBL">
              {groupedTreeData["OSBL"].map((node, index) => (
                <TreeItem
                  key={`unitOSBL-${index}`}
                  nodeId={`unitOSBL${index + 1}`}
                  onClick={() => handleTreeItemClick(node.locationId)}
                  label={`${node.locationId} - ${node.description}`}
                  icon={<InsertDriveFileIcon />}
                  style={{
                    display:
                      searchText && !node.description.includes(searchText)
                        ? "none"
                        : "block",
                  }}
                />
              ))}
            </TreeItem>
          )}

          {/* ISBL Group */}
          {groupedTreeData["ISBL"] && (
            <TreeItem key="ISBL" nodeId="4" label="ISBL">
              {groupedTreeData["ISBL"].map((node, index) => (
                <TreeItem
                  key={`unitISBL-${index}`}
                  onClick={() => handleTreeItemClick(node.locationId)}
                  nodeId={`unitISBL${index + 1}`}
                  label={`${node.locationId} - ${node.description}`}
                  icon={<InsertDriveFileIcon />}
                  style={{
                    display:
                      searchText && !node.description.includes(searchText)
                        ? "none"
                        : "block",
                  }}
                />
              ))}
            </TreeItem>
          )}
        </TreeItem>
      </TreeView>
    </Box>
  );
};

export default TreeViewComponent;
