import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Typography } from "@mui/material";

const CustomToolTip = ({ colors, selectedPath, cardData }) => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        padding: "10px",
        width: "100%",
      }}
    >
      <Typography
        variant="h5"
        component={RouterLink}
        to="/equipment"
        sx={{
          fontSize: "18px",
          fontWeight: "700",
          textDecoration: "none",
          color: "inherit",
        }}
      >
        {selectedPath}
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 1, mt: 1 }}>
        {cardData.map((card, index) => (
          <Box
            sx={{
              width: card.title === "INFORMATION" ? "300px" : "100px", // Conditional color,
              height: "80px",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
            }}
            backgroundColor={colors.primary[400]}
            style={{
              boxShadow: "rgba(0, 0, 0, 0.3) 2px 2px 8px",
              borderRadius: "5px",
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              color={colors.grey[100]}
              textAlign="center"
              p="2px"
              fontSize="12px"
              lineHeight="normal"
              height="24px"
              backgroundColor={colors.blueAccent[900]}
            >
              {card.title}
            </Box>
            {card.content === "INFORMATION" && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  padding: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    width: "100%",
                  }}
                >
                  <Typography sx={{ fontSize: 8, width: "50%" }}>Equip Type</Typography>
                  <Typography sx={{ fontSize: 8, width: "50%" }}>:</Typography>
                  <Typography sx={{ fontSize: 8, width: "50%" }}>{card.equipType}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    width: "100%",
                  }}
                >
                  <Typography sx={{ fontSize: 8, width: "50%" }}>
                    Equip Description
                  </Typography>
                  <Typography sx={{ fontSize: 8, width: "50%" }}>:</Typography>
                  <Typography sx={{ fontSize: 8, width: "50%" }}>
                    {card.equipDescription}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    width: "100%",
                  }}
                >
                  <Typography sx={{ fontSize: 8, width: "50%" }}>Impact on the FS</Typography>
                  <Typography sx={{ fontSize: 8, width: "50%" }}>:</Typography>
                  <Typography sx={{ fontSize: 8, width: "50%" }}>
                    {card.impactOnFS}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    width: "100%",
                  }}
                >
                  <Typography sx={{ fontSize: 8, width: "50%" }}>Likelihood</Typography>
                  <Typography sx={{ fontSize: 8, width: "50%" }}>:</Typography>
                  <Typography sx={{ fontSize: 8, width: "50%" }}>
                    {card.likelihood}
                  </Typography>
                </Box>
              </Box>
            )}
            {/* Render other content as usual */}
            {card.content !== "INFORMATION" && (
              <Box
                sx={{
                  display: "flex",
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  width: "100%",
                  height: "100%",
                  fontSize: "24px",
                  fontWeight: "700",
                  color: card.content === "MH" ? "#FE9900" : card.color,
                  "&::-webkit-scrollbar": {
                    width: "8px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: colors.blueAccent[900],
                    borderRadius: "0px",
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: colors.grey[800],
                  },
                }}
              >
                {card.content}
                <Typography sx={{ fontSize: "10px", color: "#fff" }}>
                  {card.status}
                </Typography>
              </Box>
            )}{" "}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CustomToolTip;
