import React, { useState } from "react";
import { BarChart } from "@mui/x-charts/BarChart";

const VerticalBarChart = ({ realData }) => {
  const [seriesVisibility, setSeriesVisibility] = useState(
    realData.map(() => true) // Initialize all series as visible
  );

  const xLabels = realData[0]?.kategori.map(
    (label) => (label.length > 4 ? label.substring(0, 5) + "..." : label)
  ) || [];
  const colors = realData.map((item) => item.riskColor);
  const reversedRealData = [...realData].reverse();

  const handleLegendClick = (index) => {
    const updatedVisibility = [...seriesVisibility];
    updatedVisibility[index] = !updatedVisibility[index];
    setSeriesVisibility(updatedVisibility);
  };

  return (
    <BarChart
      margin={{ top: 20, bottom: 50, left: 50, right: 20 }}
      layout="vertical"
      series={reversedRealData.map((item, index) => ({
        data: item.udata,
        label: item.risk,
        stack: "stack1",
        color: colors[colors.length - 1 - index], // Adjust color order accordingly
        visible: seriesVisibility[index], // Pass visibility status to series
      }))}
      xAxis={[
        {
          data: xLabels,
          scaleType: "band",
        },
      ]}
      barSize={200}
      slotProps={{
        legend: {
          labelStyle: {
            fontSize: 10,
          },
          direction: "row",
          position: { vertical: "bottom", horizontal: "middle" },
          padding: 1,
          itemMarkWidth: 11,
          itemMarkHeight: 8,
          markGap: 5,
          itemGap: 20,
          onClick: (_, index) => handleLegendClick(index), // Handle legend item click
        },
      }}
    />
  );
};

export default VerticalBarChart;
