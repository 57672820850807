import axios from 'axios';

const pfdInstance = axios.create({
  baseURL: 'https://be-fsca.aidainspection.com/api/Pfd', // Corrected base URL for PFD
  headers: {
    'Content-Type': 'application/json',
    // Add any common headers here
  },
});

// Add request interceptor
const requestInterceptor = (config) => {
  // You can modify the request config here, such as adding authentication headers
  return config;
};

pfdInstance.interceptors.request.use(requestInterceptor);

// Add response interceptor
const responseInterceptor = (response) => {
  // You can modify the response here
  return response;
};

pfdInstance.interceptors.response.use(responseInterceptor);

// Create PFD-specific functions to make API calls
export const getTreeViewData = async () => {
  try {
    const response = await pfdInstance.get('/treeView');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllSheetSvg = async (locationId) => {
  try {
    const response = await axios.get(`/api/Pfd/allSheetSvg?locationId=${locationId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching all sheets:', error);
    throw error;
  }
};

export const getRiskFscaByUnit = async (locationId) => {
  try {
    const response = await pfdInstance.get('/riskFscaByUnit', {
      params: { locationId },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRiskEcaByUnit = async (locationId) => {
  try {
    const response = await pfdInstance.get('/riskEcaByUnit', {
      params: { locationId },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCategoryByUnit = async () => {
  try {
    const response = await pfdInstance.get('/categoryByUnit');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSvgData = async (locationId) => {
  try {
    const response = await pfdInstance.get('/svgData', {
      params: { locationId },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default pfdInstance;
