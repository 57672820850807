import React, { useState, useEffect, useMemo } from "react";
import Chart from "react-google-charts";
import { getModalAnalythic } from "./api";
import {
  formatRiskLevel,
  getChartOptions,
  getTableColumnsAnalythic,
} from "./utils";
import CustomDialogAnalythic from "./CustomDialogAnalythic";
import DynamicSnackbarAlert from "../../Global/DynamicSnackbarAlert";

const CustomPieChartAnalythic = ({
  data,
  title,
  headerNames,
  riskColors,
  colors,
  detailEndpoint,
  locationId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSlice, setSelectedSlice] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [chartEventMessage, setChartEventMessage] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const tableColumns = useMemo(
    () => getTableColumnsAnalythic(detailEndpoint),
    [detailEndpoint]
  );
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [infoSnackbarOpen, setInfoSnackbarOpen] = useState(false);

  useEffect(() => {
    fetchDataForSelectedSlice();
  }, [selectedSlice, locationId]);

  const fetchDataForSelectedSlice = async () => {
    if (selectedSlice !== null && data && data[selectedSlice + 1]) {
      try {
        setIsLoading(true);
        setError(null);
        setSuccess(null);

        const selectedRisk = data[selectedSlice + 1][0];
        const jsonData = await getModalAnalythic(
          detailEndpoint,
          locationId,
          selectedRisk
        );

        setModalData(jsonData);
        setSuccess(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("An error occurred. Please try again later.");
        setSuccess(false);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleChartSelect = (ChartSelection) => {
    if (ChartSelection && ChartSelection.length > 0) {
      const selectedSliceIndex = ChartSelection[0].row;
      setSelectedSlice(selectedSliceIndex);
      if (
        data &&
        data[selectedSliceIndex + 1] &&
        selectedSliceIndex !== selectedSlice
      ) {
        //setSelectedSlice(selectedSliceIndex);
        const selectedRisk = data[selectedSliceIndex + 1][0];
        const formattedRisk = formatRiskLevel(selectedRisk);
        setChartEventMessage(`Selected Risk: ${formattedRisk.label}`);
        setSnackbarOpen(true);
      } else {
        console.warn(
          "Invalid selected slice index or data is undefined or duplicate selection"
        );
      }
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setChartEventMessage(null); // Reset chart event message when closing the modal
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessSnackbarOpen(false);
    setInfoSnackbarOpen(false);
  };

  useEffect(() => {
    // Show success message for 2 seconds
    if (success !== null) {
      setSuccessSnackbarOpen(true);
      setTimeout(() => {
        setSuccessSnackbarOpen(false);
      }, 1500);
    }

    // Show info message after a delay of 2 seconds
    if (chartEventMessage !== null) {
      setTimeout(() => {
        setInfoSnackbarOpen(true);
        setTimeout(() => {
          setInfoSnackbarOpen(false);
        }, 1500);
      }, 1500);
    }
  }, [success, chartEventMessage]);

  const selectedRisk = data[selectedSlice + 1]?.[0];
  const formattedRisk = formatRiskLevel(selectedRisk);

  return (
    <>
      <Chart
        chartType="PieChart"
        data={data}
        options={getChartOptions(title, colors, riskColors)}
        chartEvents={[
          {
            eventName: "select",
            callback: ({ chartWrapper }) => {
              const chart = chartWrapper.getChart();
              const selection = chart.getSelection();
              handleChartSelect(selection);
              openModal();
            },
          },
        ]}
        width={"100%"}
        height={"100%"}
      />
      <CustomDialogAnalythic
        formattedRisk={formattedRisk}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        headerNames={headerNames}
        colors={colors}
        modalData={modalData}
        isLoading={isLoading}
        error={error}
        detailEndpoint={detailEndpoint}
        tableColumns={tableColumns}
      />
      {/* Snackbar for success or error */}
      {success !== null && (
        <DynamicSnackbarAlert
          open={successSnackbarOpen}
          severity={success ? "success" : "error"}
          message={
            success
              ? "Data successfully retrieved."
              : "Error fetching data. Please try again later."
          }
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        />
      )}
      {/* Snackbar for chart event */}
      <DynamicSnackbarAlert
        open={infoSnackbarOpen}
        severity="info"
        message={chartEventMessage}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </>
  );
};

export default CustomPieChartAnalythic;
