import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

// color design tokens export
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        grey: {
          100: "#fff", 
          200: "#f5f5f5", 
          300: "#e0e0e0", 
          400: "#999999", 
          500: "#cccccc", 
          600: "#b0b0b0", 
          700: "#999999", 
          800: "#808080", 
          900: "#666666", 

        },
        primary: {
          100: "#d0d1d5",
          200: "#a1a4ab",
          300: "#191E22", //bg dark dekstop  191E22
          400: "#242A30", //bg dark card
          500: "#23313E", //bg treeview inner
          600: "#101624",
          700: "#0c101b",
          800: "#080b12",
          900: "#040509",
        },
        greenAccent: {
          100: "#dbf5ee",
          200: "#b7ebde",
          300: "#94e2cd",
          400: "#70d8bd",
          500: "#4cceac",
          600: "#3da58a",
          700: "#2e7c67",
          800: "#1e5245",
          900: "#0f2922",
        },
        redAccent: {
          100: "#f8dcdb",
          200: "#f1b9b7",
          300: "#e99592",
          400: "#e2726e",
          500: "#db4f4a",
          600: "#af3f3b",
          700: "#832f2c",
          800: "#58201e",
          900: "#2c100f",
        },
        blueAccent: {
          100: "#e1e2fe",
          200: "#c3c6fd",
          300: "linear-gradient(180deg, #05498F 53.53%, rgba(0, 202, 190, 0.74) 100%)",
          400: "#868dfb",
          500: "#05498F", // TABLE HEADER POPUP CHART
          600: "#07a1c6", // TAG NUMBER
          700: "#075FC6", // TITLE TOP EQUIPMENT HIGHLIGHT
          800: "#2a2d64", // ACTIVE SIDEBAR ITEM
          900: "#05498F", // CARD TITLE BACKGROUND
          
        },
        yellow: {
          100: "#fff7db",
          200: "#ffebaa",
          300: "#ffe179",
          400: "#ffd648",
          500: "#ffcc16",
          600: "#d4a90f",
          700: "#ad860b",
          800: "#856308",
          900: "#5e3f04",
        },
        pink: {
          100: "#fdd3e0",
          200: "#f9a6c1",
          300: "#f578a2",
          400: "#f24b83",
          500: "#ee1d64",
          600: "#c51a4e",
          700: "#9a1740",
          800: "#700e32",
          900: "#460a24",
        },
      }
    : {
        grey: {
          100: "#fff", 
          200: "#f5f5f5", 
          300: "#e0e0e0", 
          400: "#FFF", 
          500: "#cccccc", 
          600: "#b0b0b0", 
          700: "#999999", 
          800: "#333", // INTEGRITY STATUS
          900: "#ffffff", 
        },
        yellow: {
          100: "#fff7db",
          200: "#ffebaa",
          300: "#ffe179",
          400: "#ffd648",
          500: "#ffcc16",
          600: "#d4a90f",
          700: "#ad860b",
          800: "#856308",
          900: "#5e3f04",
        },
        pink: {
          100: "#fdd3e0",
          200: "#f9a6c1",
          300: "#f578a2",
          400: "#f24b83",
          500: "#ee1d64",
          600: "#c51a4e",
          700: "#9a1740",
          800: "#700e32",
          900: "#460a24",
        },
        primary: {
          100: "#333",
          200: "#080b12",
          300: "#F4F5F8",
          400: "#FFF", // manually changed
          500: "#F4F5F8", //bgtreeviewinner
          600: "#1F2A40",
          700: "#727681",
          800: "#a1a4ab",
          900: "#d0d1d5",
        },
        greenAccent: {
          100: "#0f2922",
          200: "#1e5245",
          300: "#2e7c67",
          400: "#3da58a",
          500: "#4cceac",
          600: "#70d8bd",
          700: "#94e2cd",
          800: "#b7ebde",
          900: "#fff",
        },
        redAccent: {
          100: "#2c100f",
          200: "#58201e",
          300: "#832f2c",
          400: "#af3f3b",
          500: "#db4f4a",
          600: "#e2726e",
          700: "#e99592",
          800: "#f1b9b7",
          900: "#f8dcdb",
        },
        blueAccent: {
          100: "#151632",
          200: "#2a2d64",
          300: "linear-gradient(180deg, #1C84EE 52.33%, rgba(0, 202, 190, 0.74) 100%)",
          400: "#075FC6",
          500: "#1C84EE", //TABLE HEADER POPUP CHART
          600: "#07a1c6",
          700: "#075FC6", //TITLE TOP EQUIPMENT HIGHLIGHT
          800: "#05498f", //ACTIVE SIDEBAR ITEM
          900: "#1C84EE", //CARD TITLE BACKGROUND
        },
      }),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              main: colors.primary[100],
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: colors.primary[500],
            },
          }
        : {
            // palette values for light mode
            primary: {
              main: colors.primary[100],
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: colors.greenAccent[900],
            },
          }),
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("dark");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};
