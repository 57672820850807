// CustomDialog.js
import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, Divider } from "@mui/material";
import DataTableAnalythic from "./DataTableAnalythic";

const CustomDialogAnalythic = ({
  isModalOpen,
  closeModal,
  colors,
  modalData,
  detailEndpoint,
  tableColumns,
  formattedRisk,
}) => {
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortColumn, setSortColumn] = useState(null);

  useEffect(() => {
    setFilteredData(modalData || []);
  }, [modalData]);

  const handleSearch = (term) => {
    setSearchTerm(term);
    filterData(term, sortColumn);
  };

  const handleSort = (column) => {
    setSortColumn(column);
    filterData(searchTerm, column);
  };

  const filterData = (searchTerm, sortColumn) => {
    let filtered = (modalData || []).filter((item) =>
      Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );

    if (sortColumn) {
      filtered = [...filtered].sort((a, b) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];
        return aValue.localeCompare(bValue);
      });
    }

    setFilteredData(filtered);
  };

  return (
    <Dialog
      sx={{
        borderRadius: "16px",
        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
        backdropFilter: "blur(7.4px)",
        WebkitBackdropFilter: "blur(7.4px)",
        border: "1px solid rgba(255, 255, 255, 0.3)",
      }}
      open={isModalOpen}
      onClose={closeModal}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        {detailEndpoint
          .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space before capital letters
          .split(" ")
          .map( 
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          ) // Capitalize each word
          .join(" ")}
        <span style={{ color: formattedRisk.color, marginLeft: "5px" }}>
          {formattedRisk.label}
        </span>
      </DialogTitle>{" "}
      <Divider
        sx={{
          padding: 0,
          margin: 0,
        }}
      />
      <DialogContent sx={{ padding: 0.5, margin: 0 }}>
        <DataTableAnalythic
          data={filteredData}
          columns={tableColumns}
          searchTerm={searchTerm}
          onSearch={handleSearch}
          sortColumn={sortColumn}
          onSort={handleSort}
          colors={colors}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CustomDialogAnalythic;
