import React, { useEffect, useCallback } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./map.css";
import { getMapLocation } from "../../../pages/Dashboard/Service/axios";

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

const Maps = ({ mapLocationData }) => {
  const setupMap = useCallback(() => {
    mapboxgl.accessToken = MAPBOX_TOKEN;
    const map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/satellite-v9",
      center: [116.8192836, -1.2487179],
      zoom: 14,
      attributionControl: false,
    });

    map.on("load", () => {
      mapLocationData.forEach((location) => {
        const [lng, lat] = [
          parseFloat(location.longitude),
          parseFloat(location.latitude),
        ];

        const marker = new mapboxgl.Marker({
          draggable: false,
          color: "rgb(188, 12, 12)",
        })
          .setLngLat([lng, lat])
          .addTo(map);

        const tooltipContent = `
        <div class="wrapper">
        <div class="glassmorphism-popup">
          <h3 class="popup-title">${location.id}</h3>
          <hr class="popup-divider" />
          <p class="popup-description">${location.description}</p>
          <p class="popup-description">Entity ID: ${location.entityId}</p>
          <p class="popup-description">Process Type: ${location.processType}</p>
        </div>
      </div>
        `;

        const tooltip = new mapboxgl.Popup({
          offset: 25,
          closeButton: false,
          closeOnClick: false,
        }).setHTML(tooltipContent);

        marker.setPopup(tooltip);

        marker.getElement().addEventListener("mouseover", () => {
          marker.togglePopup();
        });

        marker.getElement().addEventListener("mouseout", () => {
          marker.togglePopup();
        });

        marker.getElement().style.cursor = "pointer";
      });
    });

    return map;
  }, [mapLocationData]);

  useEffect(() => {
    const map = setupMap();

    return () => map.remove();
  }, [setupMap]);

  return (
    <div
      id="map"
      style={{ width: "100%", height: "100%", borderRadius: "5px" }}
    />
  );
};

export default Maps;
