import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Box,
  TablePagination,
  TableContainer,
} from "@mui/material";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";

const DataTable = ({
  data,
  columns,
  colors,
  defaultRowsPerPage = 50, // Default number of rows per page
}) => {
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [page, setPage] = useState(0); // State for pagination
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage); // State for rows per page

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const sortData = (column) => {
    // You need to implement sorting logic here
    // For simplicity, I'm just toggling between ascending and descending order
    console.log("Sorting by column:", column);
  };

  const getColumnHeaderLabel = (column) => {
    const headerLabels = {
      fsCode: "Functional System Code",
      drawingNo: "Drawing Number",
      fsName: "Functional System Name",
      fsDescription: "Functional System Description",
      failureScenario: "Failure Scenario",
      likelihood: "Likelihood",
      criticalityCode: "Criticality Code",
      ram: "RAM Criticality",
      locationId: "Location",
      tagNumber: "Equip Tag",
      kategori: "Equip Type",
      description: "Description",
      impact: "Impact",
      likelihoodECA: "Likelihood",
      criticalityCodeECA: "Criticality Code",
      ramECA: "RAM",
    };

    return headerLabels[column] || column;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData = data.filter((row) =>
    columns.some((column) =>
      row[column].toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <Box sx={{ padding: "16px", maxHeight: "500px", width: "100%" }}>
      <TextField
        label="Search"
        value={searchTerm}
        onChange={(e) => handleSearch(e.target.value)}
        variant="outlined"
        sx={{ width: "100%", marginBottom: "16px" }}
        size="small"
      />
      <TableContainer
        sx={{
          maxHeight: 380,
          "&::-webkit-scrollbar": {
            width: "8px",
            borderRadius: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "#555",
            },
          },
        }}
      >
        <Table size="xl">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column} onClick={() => sortData(column)}>
                  {getColumnHeaderLabel(column)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={index}>
                  {columns.map((column) => (
                    <TableCell key={column}>{row[column]}</TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box> 
  );
};

export default DataTable;
