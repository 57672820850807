import React, { useState } from "react";
import {
  Box,
  Divider,
  InputAdornment,
  IconButton,
  FormControl,
  Button,
  TextField,
  Alert,
} from "@mui/material";
import bgLogin from "../../assets/kilang-pertamina.jpeg";
import LeftLogo from "../../assets/LOGO PNRE.png";
import RightLogo from "../../assets/KPB WARNA.png";
import logoOne from "../../assets/Logo One Pertamina-Primary Color.png";
import logoBumn from "../../assets/logobumn.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleLogin = () => {
    const username = "admin@gmail.com";
    const password = "password";

    if (formData.email === username && formData.password === password) {
      navigate("/dashboard");
    } else {
      setError("Invalid username or password");
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${bgLogin})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "400px",
          height: "480px",
          boxShadow: "0px 5px 4px 8px rgba(0, 0, 0, 0.25)",
          borderRadius: "20px",
          opacity: 0.95,
          background: "rgba(255, 255, 255, 0.9)",
          padding: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "flex-start",
            height: 60,
            marginBottom: 3,
          }}
        >
          <img
            src={LeftLogo}
            alt="leftlogo"
            style={{
              width: "48%",
              height: "100%",
              objectFit: "cover",
            }}
          />
          <img
            src={RightLogo}
            alt="rightlogo"
            style={{
              width: "40%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </Box>
        <Divider
          sx={{ width: "100%", height: "1px", backgroundColor: "black" }}
        />
        {/* MIDDLE SECTION */}
        <FormControl sx={{ width: "100%" }}>
          {["email", "password"].map((field) => (
            <TextField
              key={field}
              label={field === "email" ? "Username" : "Password"}
              variant="outlined"
              color="primary"
              type={field === "email" ? "email" : showPassword ? "text" : "password"}
              name={field}
              value={formData[field]}
              onChange={handleChange}
              fullWidth
              margin="normal"
              InputProps={{
                sx: {
                  borderRadius: 20,
                  backgroundColor: "#F3F3F3",
                  color: "black",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    {field === "email" ? (
                      <EmailIcon sx={{ color: "black" }} />
                    ) : (
                      <LockIcon sx={{ color: "black" }} />
                    )}
                  </InputAdornment>
                ),
                endAdornment: field === "password" && (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      sx={{ color: "black" }}
                      onClick={handleClickShowPassword}
                      aria-label="Toggle password visibility"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                sx: {
                  "&.Mui-focused": {
                    color: "#000000 !important", // Adjust focused label color
                  },
                },
              }}
              focused={true}
            />
          ))}
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={handleLogin}
          fullWidth
          size="large"
          sx={{
            marginTop: 2,
            borderRadius: 20,
            background:
              "linear-gradient(270deg, #00CABE 0%, #0CACD3 48.42%, #1C84EE 100%)",
          }}
        >
          Login
        </Button>
        {error && (
          <Alert severity="error" sx={{ marginTop: 2 }} onClose={() => setError("")}>
            {error}
          </Alert>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            height: 60,
            marginTop: 6,
          }}
        >
          <Box sx={{ width: "50%" }}>
            <img
              src={logoBumn}
              alt="leftlogo"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </Box>
          <Box sx={{ width: "50%" }}>
            <img
              src={logoOne}
              alt="rightlogo"
              style={{
                width: "100%",
                height: "50%",
                objectFit: "contain",
                marginTop: 5,
                display: "flex",
                alignSelf: "end",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
