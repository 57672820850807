import axios from "axios";

const dashboardInstance = axios.create({
  baseURL: "https://be-fsca.aidainspection.com/api/Dashboard",
  headers: {
    Accept: "text/plain",
  },
});

// Add request interceptor
const requestInterceptor = (config) => {
  // You can modify the request config here, such as adding authentication headers
  config.mode = "no-cors"; // Set mode directly in the request config
  return config;
};

dashboardInstance.interceptors.request.use(requestInterceptor);

// Add response interceptor
const responseInterceptor = (response) => {
  // You can modify the response here
  return response;
};

dashboardInstance.interceptors.response.use(responseInterceptor);

// Dashboard API calls
export const getSummaryRiskFsca = async () => {
  try {
    const response = await dashboardInstance.get("/summaryRiskFsca");
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Add the new API call for detailRiskFsca
export const getDetailRiskFsca = async (risk) => {
  try {
    const response = await dashboardInstance.get("/detailRiskFsca", {
      params: {
        risk: risk,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Add the new API call for detailRiskEca
export const getDetailRiskEca = async (risk) => {
  try {
    const response = await dashboardInstance.get("/detailRiskEca", {
      params: {
        risk: risk,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getMapLocation = async () => {
  try {
    const response = await dashboardInstance.get("/getMapLocation");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSummaryRiskEca = async () => {
  try {
    const response = await dashboardInstance.get("/summaryRiskEca");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSummaryOSBL = async () => {
  try {
    const response = await dashboardInstance.get("/summaryOSBL");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSummaryISBL = async () => {
  try {
    const response = await dashboardInstance.get("/summaryISBL");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSummaryTopHighlight = async () => {
  try {
    const response = await dashboardInstance.get("/topHighlight");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSummaryByUnit = async (locationId) => {
  try {
    // Append the locationId as a query parameter
    const response = await dashboardInstance.get("/summaryByUnit", {
      params: {
        locationId: locationId,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

// PFD API calls
// Add your PFD-specific API calls here using pfdInstance

export default {
  dashboardInstance,
};
