import React, { useState, useEffect } from "react";
import { Box, useTheme, Select, MenuItem } from "@mui/material";
import { tokens } from "../../../theme";

function CustomCard({
  gridColumn,
  gridRow,
  width,
  height,
  backgroundColor,
  boxShadow,
  borderRadius,
  title,
  children,
  sheets,
  selectedSheet,
  setSelectedSheet,
  setSelectedFilename,
}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isProcessFlowDiagram = title === "PROCESS FLOW DIAGRAM";
  const titleHeight = isProcessFlowDiagram ? "40px" : "24px";
  const justifyContentTitle = isProcessFlowDiagram ? "space-between" : "center";

  const renderDropdown = isProcessFlowDiagram;
  return (
    <Box
      gridColumn={gridColumn}
      gridRow={gridRow}
      sx={{
        width,
        height,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
      backgroundColor={backgroundColor}
      style={{
        boxShadow,
        borderRadius,
      }}
    >
      <Box
        display="flex"
        justifyContent={justifyContentTitle}
        alignItems="center"
        color={colors.grey[100]}
        textAlign="center"
        px="10px"
        fontSize="12px"
        lineHeight="normal"
        height={titleHeight}
        width="100%"
        backgroundColor={colors.blueAccent[900]}
      >
        {title}
        {renderDropdown && sheets.length > 0 && (
          <Select
            value={selectedSheet}
            onChange={(event) => {
              setSelectedSheet(event.target.value);
              const selectedData = sheets.find((data) => data.sheet === event.target.value);
              if (selectedData) {
                setSelectedFilename(selectedData.filename);
              }   
            }}
            variant="standard"
            sx={{  }}
          >
            {sheets.map((data) => (
              <MenuItem key={data.sheet} value={data.sheet}>
                {data.filename}
              </MenuItem>
            ))}
          </Select>
        )}
      </Box>
      <Box
        flex="1"
        overflow="auto"
        padding="8px"
        top={0}
        sx={{
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: colors.blueAccent[900],
            borderRadius: "0px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: colors.grey[800],
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

export default CustomCard;
