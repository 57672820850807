import React, { useState } from "react";
import { Box, Divider, useTheme } from "@mui/material";
import {
  ContainerAnalytic,
  TabsComponent,
  Topbar,
  TreeViewComponent,
} from "../../components";
import { tokens } from "../../theme";
import { riskFscaData, tableData } from "../../constants/Charts/pieData";

const DataAnalytic = ({ src }) => {
  //Theme
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  //variable state
  const [selectedLocationId, setSelectedLocationId] = useState(null);

  const handleLocationSelect = (locationId) => {
    setSelectedLocationId(locationId);
  };

  return (
    <Box
      sx={{
        backgroundColor: colors.primary[300],
        maxHeight: "100vh",
        minHeight: "100vh",
        padding: "10px",
        overflow: "hidden",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        paddingBottom="10px"
      >
        {/* CONTAINER TOP */}
        <Box>
          {/* TABS */}
          <TabsComponent />
        </Box>
        <Topbar />
      </Box>
      <Divider variant="middle" sx={{ margin: "5px" }} />
      <Box display="grid" gridTemplateColumns="20% 80%" gap="10px">
        {/* CONTAINER TREEVIEW */}
        <Box sx={{ mt: 1 }}>
          <TreeViewComponent onLocationSelect={handleLocationSelect} />
        </Box>
        {/* CONTAINER CONTENT */}
        <Box borderRadius="10px">
          <ContainerAnalytic
            colors={colors}
            riskFscaData={riskFscaData}
            tableData={tableData}
            selectedLocationId={selectedLocationId}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DataAnalytic;
