import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";

const TabsComponent = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      width="110px"
      height="20px"
    >
      {/* LEFTSECTION */}
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Data Analytics" />
        </Tabs>
      </Box>
    </Box>
  );
};

export default TabsComponent;
