import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import "./ContainerAnalytic.css";
import CustomCard from "../../Global/CustomCard";
import CriticalityDistributionTable from "../CriticalityDistribution/CriticalityDistributionTable ";
import CustomPieChart from "../../Charts/PieChart/CustomPieChart";
import RiskPathsComponent from "../SVG/ComponentPFD";
import {
  headerNamesEca,
  headerNamesFSCA,
} from "../../../constants/Charts/pieData";
import {
  getRiskEcaByUnit,
  getRiskFscaByUnit,
} from "../../../pages/DataAnalytic/service/axios";
import CustomPieChartAnalythic from "../../Charts/PieChart/CustomPieChartAnalythic";
import DynamicSnackbarAlert from "../../Global/DynamicSnackbarAlert";

const ContainerAnalytic = ({ colors, selectedLocationId }) => {
  const [riskFscaSummary, setRiskFscaSummary] = useState([]);
  const [riskEcaSummary, setRiskEcaSummary] = useState([]);
  const [riskColors, setRiskColors] = useState([]);
  const [riskColorsEca, setRiskColorsEca] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [error, setError] = useState(null);
  const [sheets, setSheets] = useState([]);
  const [selectedSheet, setSelectedSheet] = useState("1");
  const [selectedFilename, setSelectedFilename] = useState(
    "331 PFD STEAM & POWER SHEET 1.svg"
  );
  const fscaRiskOrder = ["E", "H", "MH", "M", "L", "N"];
  const ecaRiskOrder = ["E", "H", "MH", "M", "L", "N"];
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [blurScreen, setBlurScreen] = useState(false);

  const handleSnackbar = (severity, message) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);

    // Automatically close the snackbar after 2000 milliseconds (2 seconds)
    setTimeout(() => {
      setSnackbarOpen(false);
    }, 1500);
  };

  //allsvgsheet
  useEffect(() => {
    if (selectedLocationId != null) {
      setBlurScreen(true);
      fetch(
        `https://be-fsca.aidainspection.com/api/Pfd/allSheetSvg?locationId=${selectedLocationId}`
      )
        .then((response) => response.json())
        .then((data) => {
          const sheetNumbers = data.map((sheet) => sheet.sheet);
          setSheets(data);
          setSelectedSheet(sheetNumbers[0]);
          setSelectedFilename(data[0].filename);
          handleSnackbar("success", "All Sheet svg data successfully loaded");
        })
        .catch((error) => console.error("Error fetching data:", error));
        handleSnackbar("error", "Error loading Sheet svg data");

    } else {
      fetch(
        `https://be-fsca.aidainspection.com/api/Pfd/allSheetSvg?locationId=U_331`
      )
        .then((response) => response.json())
        .then((data) => {
          const sheetNumbers = data.map((sheet) => sheet.sheet);
          setSheets(data);
          setSelectedSheet(sheetNumbers[0]);
          setSelectedFilename(data[0].filename);
          handleSnackbar("success", "get location 331");
        })
        .catch((error) => console.error("Error fetching data:", error));
        handleSnackbar("error", "failed get data 331");

    }
    setBlurScreen(false);
  }, [selectedLocationId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch and process riskFscaData
        const riskFscaData = await getRiskFscaByUnit(selectedLocationId); // Pass locationId
        setBlurScreen(true);

        if (riskFscaData && riskFscaData.length > 0) {
          // Extract all unique risk levels
          const allRiskLevels = [...new Set(riskFscaData.map(data => data.risk))];
    
          // Initialize an empty array to hold the formatted data
          const formattedRiskFscaData = [];
    
          // Iterate over all risk levels and check if data is available for each
          allRiskLevels.forEach(riskLevel => {
            const riskData = riskFscaData.find(data => data.risk === riskLevel);
            const jumlah = riskData ? riskData.jumlah : 0;
            formattedRiskFscaData.push([riskLevel, jumlah]);
          });
    
          // Sort the formatted data based on predefined order
          const sortedRiskFscaData = formattedRiskFscaData.sort(
            (a, b) => fscaRiskOrder.indexOf(a[0]) - fscaRiskOrder.indexOf(b[0])
          );
    
          // Extract colors for each risk level
          const colors = sortedRiskFscaData.map((item) => {
            const riskData = riskFscaData.find(data => data.risk === item[0]);
            return riskData ? riskData.riskColor : '#000000';
          });
    
          setRiskColors(colors);
          setRiskFscaSummary([["Risk", "Jumlah"], ...sortedRiskFscaData]);
          handleSnackbar("success", "Risk FSCA data successfully loaded");
          await new Promise((resolve) => setTimeout(resolve, 1000));
        } else {
          console.error("Error: No data or invalid data received for riskFsca");
          handleSnackbar("error", "Error loading Risk FSCA data");
        }
        // Fetch and process riskEcaData
        const riskEcaData = await getRiskEcaByUnit(selectedLocationId);
        if (riskEcaData && riskEcaData.length > 0) {
          const sortedRiskEcaData = riskEcaData.sort(
            (a, b) =>
              ecaRiskOrder.indexOf(a.risk) - ecaRiskOrder.indexOf(b.risk)
          );
          const formattedRiskEcaData = [
            ["Risk", "Jumlah"],
            ...sortedRiskEcaData.map(({ risk, jumlah }) => [risk, jumlah]),
          ];
          const colorsEca = sortedRiskEcaData.map((item) => item.riskColor);
          setRiskColorsEca(colorsEca);
          setRiskEcaSummary(formattedRiskEcaData);
          handleSnackbar("success", "Risk ECA data successfully loaded");

        } else {
          console.error("Error: No data or invalid data received for riskEca");
          handleSnackbar("error", "Error loading Risk ECA data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
      }
      setBlurScreen(false);
    };
    fetchData();
  }, [selectedUnit, selectedLocationId]);

  return (
    <>
      <DynamicSnackbarAlert
        open={snackbarOpen}
        severity={snackbarSeverity}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />

      <Box
        sx={{
          backgroundColor: colors.primary[300],
          maxHeight: "100vh",
          minHeight: "100vh",
          padding: "10px",
          filter: blurScreen ? "blur(8px)" : "none", // Apply blur effect
        }}
      >
        <Box m="10px">
          {/* GRID & CHARTS */}
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="calc((100vh - 100px) / 3) !important"
            gap="0.5em"
          >
            <CustomCard
              gridColumn="span 4"
              gridRow="span 1"
              backgroundColor={colors.primary[400]}
              boxShadow="rgba(0, 0, 0, 0.3) 2px 2px 8px"
              borderRadius="10px"
              title="CRITICALITY DISTRIBUTION"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0px !important",
                  margin: "0px important"
                }}
              >
                <CriticalityDistributionTable
                  selectedLocationId={selectedLocationId}
                />
              </Box>
            </CustomCard>
            <CustomCard
              gridColumn="span 4"
              gridRow="span 1"
              backgroundColor={colors.primary[400]}
              boxShadow="rgba(0, 0, 0, 0.3) 2px 2px 8px"
              borderRadius="10px"
              title="FSCA SUMMARY"
            >
              <CustomPieChartAnalythic
                data={riskFscaSummary}
                colors={colors}
                headerNames={headerNamesFSCA}
                riskColors={riskColors}
                detailEndpoint="detailFscaByUnit"
                locationId={selectedLocationId}
              />
            </CustomCard>
            <CustomCard
              gridColumn="span 4"
              gridRow="span 1"
              backgroundColor={colors.primary[400]}
              boxShadow="rgba(0, 0, 0, 0.3) 2px 2px 8px"
              borderRadius="10px"
              title="ECA SUMMARY"
            >
              <CustomPieChartAnalythic
                data={riskEcaSummary}
                colors={colors}
                headerNames={headerNamesEca}
                riskColors={riskColorsEca}
                detailEndpoint="detailEcaByUnit"
                locationId={selectedLocationId}
              />
            </CustomCard>
            <CustomCard
              gridColumn="span 12"
              gridRow="span 2"
              backgroundColor="#F4F5F8"
              boxShadow="rgba(0, 0, 0, 0.3) 2px 2px 8px"
              borderRadius="10px"
              title="PROCESS FLOW DIAGRAM"
              colors={colors}
              sheets={sheets}
              selectedSheet={selectedSheet}
              setSelectedSheet={setSelectedSheet}
              setSelectedFilename={setSelectedFilename}
            >
              <RiskPathsComponent
                colors={colors}
                selectedFilename={selectedFilename}
                selectedSheet={selectedSheet}
                setSelectedSheet={setSelectedSheet}
                selectedLocationId={selectedLocationId}
              />
            </CustomCard>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ContainerAnalytic;
