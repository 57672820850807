import React, { useEffect, useState } from "react";
import { Box, Tooltip } from "@mui/material";
import CustomToolTip from "./CustomTooltipContent";
import { useNavigate } from "react-router-dom";
import { getSvgData } from "../../../pages/DataAnalytic/service/axios";
import * as d3 from "d3";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const RiskPathsComponent = ({
  cardData,
  colors,
  handleCloseTooltip,
  selectedSheet,
  setSelectedSheet,
  selectedFilename,
  selectedLocationId,
}) => {
  const [loading, setLoading] = useState(true);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedPath, setSelectedPath] = useState(null);
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const [svgData, setSvgData] = useState([]);
  const [error, setError] = useState(null);
  const [riskDesc, setRiskDesc] = useState(null);
  const [riskCode, setRiskCode] = useState(null);
  const [riskColor, setRiskColor] = useState(null);
  const [criticalityCode, setCriticalityCode] = useState(null);
  const [kategori, setKategori] = useState(null);
  const [eqDesc, setEqDesc] = useState(null);
  const [impact, setImpact] = useState(null);
  const [likelihood, setLikelihood] = useState(null);

  const navigate = useNavigate();
  const [selectedPaths, setSelectedPaths] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedLocationId != null) {
          const svgData = await getSvgData(selectedLocationId);
          setSvgData(svgData);
        } else {
          const svgData = await getSvgData("U_331");
          setSvgData(svgData);
        }
      } catch (error) {
        console.error("Error fetching SVG data:", error);
        setError(error);
      }
    };

    fetchData();
  }, [selectedLocationId]);

  useEffect(() => {
    d3.xml(
      `https://be-fsca.aidainspection.com/wwwroot/upload/PFD/KBP/${selectedFilename}`
    ).then((data) => {
      const svgContainer = document.getElementById("svg-container");
      svgContainer.innerHTML = "";

      const svg = d3.select(data.documentElement);

      const paths = svg.selectAll("path[id]");
      const pathId = Array.from(paths).filter((path) =>
        path.hasAttribute("id")
      );

      pathId.forEach((path) => {
        svgData.forEach((riskSvg) => {
          if (
            path
              .getAttribute("id")
              .replace("risk_hover_", "")
              .replace("risk_", "") == riskSvg.tagNumber
          ) {
            d3.select(path).style("fill", riskSvg.riskColor);
          }
        });
      });

      paths
        .on("mouseover", handleMouseOver)
        .on("mouseleave", handleMouseLeave)
        .on("click", handleClick);

      const zoom = d3.zoom().on("zoom", handleZoom);
      svg.call(zoom);

      svgContainer.appendChild(svg.node());
    });
  }, [selectedFilename, selectedLocationId]);

  const handleMouseOver = (event) => {
    const pathId = event.target.id;
    setTooltipOpen(true);
    setSelectedPath(pathId.replace("risk_hover_", "").replace("risk_", ""));
  };

  const handleMouseLeave = () => {
    setTooltipOpen(false);
    setSelectedPath(null);
  };

  const handleClick = () => {
    navigate("/equipment");
  };

  const handleZoom = (event) => {
    const { transform } = event;
    d3.select("g#risk").attr("transform", transform);
    d3.select("g#background").attr("transform", transform);
    d3.select("g#text").attr("transform", transform);
  };

  const handleSheetChange = (event) => {
    const newSheet = event.target.value;
    setSelectedSheet(newSheet);
  };

  useEffect(() => {
    const index = svgData.findIndex(
      (svgData) => selectedPath == svgData.tagNumber
    );

    if (index !== -1) {
      setRiskCode(svgData[index].ram);
      setRiskColor(svgData[index].riskColor);
      setRiskDesc(svgData[index].riskDesc);
      setCriticalityCode(svgData[index].criticalityCode);
      setKategori(svgData[index].kategori);
      setEqDesc(svgData[index].description);
      setImpact(svgData[index].impact);
      setLikelihood(svgData[index].likelihood);
    } else {
      setRiskCode("-");
      setRiskColor("inherit");
      setRiskDesc("Not Found");
      setCriticalityCode("-");
      setKategori("Not Found");
      setEqDesc("Not Found");
      setImpact("Not Found");
      setLikelihood("Not Found");
    }
  }, [selectedPath]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        position: "relative",
        "& path:hover": {
          cursor: "pointer",
          stroke: "3px",
          opacity: "0.7",
        },
      }}
    >
      <Tooltip
        open={isTooltipOpen}
        title={
          <CustomToolTip
            colors={colors}
            selectedPath={selectedPath}
            cardData={[
              { title: "RISK CODE", content: criticalityCode },
              {
                title: "RAM CRITICALITY",
                content: riskCode,
                status: riskDesc,
                color: riskColor,
              },
              {
                title: "INFORMATION",
                content: "INFORMATION",
                equipType: kategori,
                equipDescription: eqDesc,
                impactOnFS: impact,
                likelihood: likelihood,
              },
            ]}
          />
        }
        interactive
        arrow
        followCursor
      >
        <div id="svg-container"></div>
      </Tooltip>
    </Box>
  );
};

export default RiskPathsComponent;
