import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import axios from "axios";

const abbreviateLocationId = (locationId) => {
  switch (locationId) {
    case "ELECTRICAL":
      return "ELEC";
    case "INSTRUMENT":
      return "INST";
    case "ROTATING":
      return "ROT";
    case "STATIONARY":
      return "STAT";
    // Add more cases for other locationIds as needed
    default:
      return locationId;
  }
};

const CustomTable = ({ selectedLocationId }) => {
  const tableHeader = ["Unit", "E", "H", "MH", "M", "L", "N"];
  const [apiData, setApiData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://be-fsca.aidainspection.com/api/Pfd/categoryByUnit?locationId=${
            selectedLocationId || ""
          }`,
          {
            headers: {
              Accept: "application/json",
            },
          }
        );
        setApiData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedLocationId]);

  const getBackgroundColor = (header) => {
    switch (header) {
      case "E":
        return "brown";
      case "H":
        return "red";
      case "MH":
        return "orange";
      case "M":
        return "#ffea98";
      case "L":
        return "lightgreen";
      case "N":
        return "green";
      default:
        return "white";
    }
  };

  return (
    <TableContainer
      component={Paper}
      style={{
        border: "1px solid #ddd",
        width: "100%",
        height: "170px",
        overflow: "auto",
        scrollbarWidth: "thin" /* For Firefox */,
        scrollbarColor: "rgba(0, 0, 0, 0.5) transparent" /* For Firefox */,
        WebkitOverflowScrolling: "touch" /* For iOS momentum scrolling */,
        borderRadius: "4px" /* Optional: Add border radius to scrollbar */,
        padding: "0px important",
        margin: "0px important"

      }}
    >
      <Table style={{ width: "100%", tableLayout: "fixed" }}>
        <TableHead>
          <TableRow>
            {tableHeader.map((header) => (
              <TableCell
                key={header}
                style={{
                  backgroundColor: getBackgroundColor(header),
                  color: "black",
                  fontWeight: "bold",
                  border: "1px solid #ddd",
                  padding: "10px", // Adjust cell padding
                  textAlign: "center",
                }}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {apiData.map((row, index) => (
            <TableRow key={index}>
              {tableHeader.map((header) => (
                <TableCell
                  key={header}
                  style={{
                    border: "1px solid #ddd",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {header === "Unit"
                    ? abbreviateLocationId(row.locationId)
                    : row[header.toLowerCase()]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
