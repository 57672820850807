import React from 'react'
import bg404 from "../../assets/bg404.png"
import { Box } from '@mui/material'

const UnderConstruction = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        overflow: "hidden", // Ensure the image doesn't overflow
      }}
    >
      <img
        src={bg404}
        alt=""
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover", // Cover the entire box without stretching
        }}
      />
    </Box>
  )
}

export default UnderConstruction
