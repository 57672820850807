// utils.js
export const getTableColumns = (detailEndpoint) => {
  const identifier = detailEndpoint.toLowerCase();
  switch (identifier) {
    case "detailriskfsca":
      return [
        "fsCode",
        "drawingNo",
        "fsName",
        "fsDescription",
        "failureScenario",
        "likelihood",
        "criticalityCode",
        "ram",
      ];
    case "detailriskeca":
      return [
        "locationId",
        "tagNumber",
        "kategori",
        "description",
        "impact",
        "likelihood",
        "criticalityCode",
        "ram",
      ];
    case "detailFscaByUnit":
      return [
        "fsCode",
        "drawingNo",
        "fsName",
        "fsDescription",
        "failureScenario",
        "likelihood",
        "criticalityCode",
        "ram",
      ];
    case "detailEcaByUnit":
      return [
        "locationId",
        "tagNumber",
        "kategori",
        "description",
        "impact",
        "likelihood",
        "criticalityCode",
        "ram",
      ];
    default:
      return [];
  }
};

export const getTableColumnsAnalythic = (detailEndpoint) => {
  const identifier = detailEndpoint.toLowerCase();
  switch (identifier) {
    case "detailfscabyunit":
      return [
        "fsCode",
        "drawingNo",
        "fsName",
        "fsDescription",
        "failureScenario",
        "likelihood",
        "criticalityCode",
        "ram",
      ];
    case "detailecabyunit":
      return [
        "locationId",
        "tagNumber",
        "kategori",
        "description",
        "impact",
        "likelihood",
        "criticalityCode",
        "ram",
      ];
    default:
      return [];
  }
};

export const getChartOptions = (title, colors, riskColors) => {
  return {
    title: title,
    pieSliceText: "label", // Show only labels in the legend
    sliceVisibilityThreshold: 0, // Show all slices
    titleTextStyle: {
      color: colors.primary[100],
      fontSize: 8,
      bold: true,
    },
    is3D: true,
    cursor: "pointer",
    legend: {
      position: "bottom",
      alignment: "center",
      textStyle: {
        fontSize: 12,
        color: colors.primary[100],
      },
    },
    colors: riskColors,
    backgroundColor: {
      fill: "transparent",
    },
    chartArea: {
      width: "100%",
      height: "80%",
      top: "5%",
    },
    pieHole: 0,
    pieSliceBorderColor: "#FFFFFF",
    pieSliceTextStyle: {
      color: colors.primary[100],
      fontSize: 10,
    },
    pieSlice: {
      textStyle: {
        fontSize: 10,
      },
      hoverable: true,
    },
  };
};

export const formatRiskLevel = (riskLevel) => {
  switch (riskLevel) {
    case "L":
      return { label: "Low", color: "#4CAF50" };
    case "N":
      return { label: "Negligible", color: "#8BC34A" };
    case "M":
      return { label: "Medium", color: "#ffea98" };
    case "MH":
      return { label: "Medium-High", color: "#FF9800" };
    case "H":
      return { label: "High", color: "#F44336" };
    case "E":
      return { label: "Extreme", color: "#D32F2F" };
    default:
      return { label: "Unknown", color: "#000000" };
  }
};
